import React from 'react';
import { graphql } from "gatsby";

import PageWrap from 'components/PageWrap';

import style from './style.module.scss';

import Markdown from 'components/Markdown';
import { SEO } from 'components/Helmets';
import { PagePicTitle } from 'components/PagePicTitle';
import { getModIconData } from 'components/ModuleIcon';

import { Separator } from 'components/Separator';
import IndyLink from 'components/IndyLink';
import { HeaderNextPrev } from 'components/Helmets';

import docManifest from 'src/content/docs/manifest.json';

export default function DocPostPage({pageContext, data}){
    const doc = data.doc;
    const curSlug = pageContext.slug;

    // Get created and last updated TS
    let createdTS = null;
    let latestTS = null;
    for (let i = 0; i < doc.frontmatter.revisions.length; i++) {
        const TS = doc.frontmatter.revisions[i].time;
        if (createdTS === null || createdTS > TS){
            createdTS = TS;
        }
        if (latestTS === null || latestTS < TS){
            latestTS = TS;
        }
    }

    if (latestTS === createdTS){
        latestTS = 0;
    }

    let path = "/learn/" + doc.fields.module + "/" + doc.fields.slug + "/";

    if (!docManifest.hasOwnProperty(doc.fields.module) || !docManifest[doc.fields.module].hasOwnProperty('posts')){
        throw new Error('Document section is not in manifest');
    }

    // Work out next and previous links, and meta title
    // Only enabled within sections
    let nextAdr, prevAdr, metaTitle;
    docSearchDone:
    for (let i = 0; i < docManifest[doc.fields.module].posts.length; i++) {
        const sect = docManifest[doc.fields.module].posts[i];
        if (sect.type == "section"){
            for (let ii = 0; ii < sect.posts.length; ii++) {
                const innerSect = sect.posts[ii];
                if (curSlug == sect.slug + "/" + innerSect.slug){
                    let sectTitle;
                    if (sect.hasOwnProperty('shortTitle')){
                        sectTitle = sect.shortTitle;
                    }else{
                        sectTitle = sect.title;
                    }

                    metaTitle = docManifest[doc.fields.module].title + " - " + sectTitle + " - " + doc.frontmatter.title;
                    
                    if (ii != 0){
                        prevAdr = "/learn/" + doc.fields.module + "/" + sect.slug + "/" + sect.posts[ii - 1].slug;
                    }
                    if (ii != sect.posts.length - 1){
                        nextAdr = "/learn/" + doc.fields.module + "/" + sect.slug + "/" + sect.posts[ii + 1].slug;
                    }

                    break docSearchDone;
                }
            }

        }else if(curSlug == sect.slug){
            metaTitle = docManifest[doc.fields.module].title + " - " + doc.frontmatter.title;
            break docSearchDone;
        }
    }

    if (!metaTitle){
        throw new Error('Document not found in manifest');
    }

    return (
        <PageWrap noSEO={true} path={path}>
            <SEO 
                headType = "DocPost"
                title = {metaTitle}
                description = {doc.frontmatter.previewText}
                createdTS = {createdTS}
                lastUpdatedTS = {latestTS}
                path = {path}
                wordCount = {doc.wordCount.words}
            />
            <HeaderNextPrev nextAdr={nextAdr} prevAdr={prevAdr} />
            
            <div id={style.container}>
                <PagePicTitle
                    imageCmp={getModIconData(doc.fields.module).component}
                    imageAlt={getModIconData(doc.fields.module).alt}>
                            <h1>{doc.frontmatter.title}</h1>
                            <p>{doc.frontmatter.previewText}</p>
                </PagePicTitle>
                <Markdown html={doc.html} contentsTable={doc.tableOfContents} />
                <Separator />
                <div className={style.lineNav}>
                    {prevAdr && 
                        <IndyLink adr={prevAdr} className={style.prev}>
                            <i className="fa fa-arrow-left" />
                            <p>Previous</p> 
                        </IndyLink>
                    }
                    <IndyLink adr={"/learn/" + doc.fields.module} className={style.up}>
                        <p>Contents</p>
                    </IndyLink>
                    {nextAdr && 
                        <IndyLink adr={nextAdr} className={style.next}>
                            <p>Next</p>
                            <i className="fa fa-arrow-right" />
                        </IndyLink>
                    }
                </div>
            </div>
        </PageWrap>
    );
}

//eslint-disable-next-line no-undef
export const query = graphql`
query DocBySlug($module: String, $slug: String) {
    doc: markdownRemark(
        fields: {
            slug: {
                eq: $slug
            },
            module: {
                eq: $module
            }
            mdType: {
                eq: "doc"
            }
        }
    ) {
        html
        tableOfContents(
            absolute: false
        )
        frontmatter {
            title
            previewText
            revisions {
                time
                title
            }
        }
        fields {
            slug
            module
            readingTime {
                text
            }
        }
        wordCount{
            words
        }
    }
}
`;